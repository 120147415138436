<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="省" prop="provinceCode" >
        <a-select v-model="form.provinceCode" @change="selectProvince">
          <a-select-option :value="item.areaId" v-for="item in provinceList">{{item.areaName}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="市" prop="cityCode" >
        <a-select v-model="form.cityCode" @change="selectCity">
          <a-select-option :value="item.areaId" v-for="item in cityList">{{item.areaName}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="区" prop="areaCode" >
        <a-select v-model="form.areaCode" @change="selectArea">
          <a-select-option :value="item.areaId" v-for="item in areaList">{{item.areaName}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="设备图片" prop="imgs" >
        <file-upload type="image" :count="1" :defaultList="form.imgsArr" @input="getImg($event, 'imgs')"></file-upload>
      </a-form-model-item>
      <a-form-model-item label="设备名称" prop="name" >
        <a-input v-model="form.name" placeholder="请输入设备名称" />
      </a-form-model-item>
      <a-form-model-item label="设备编号" prop="number" >
        <a-input v-model="form.number" placeholder="请输入设备编号" />
      </a-form-model-item>
      <a-form-model-item label="设备型号" prop="model" >
        <a-input v-model="form.model" placeholder="请输入设备型号" />
      </a-form-model-item>
      <a-form-model-item label="使用地点" prop="address" >
        <a-input v-model="form.address" placeholder="请输入使用地点" />
      </a-form-model-item>
      <a-form-model-item label="下次检验日期" prop="nextInspectionTime" >
        <a-date-picker v-model="form.nextInspectionTime"></a-date-picker>
      </a-form-model-item>
      <a-form-model-item label="操作员姓名" prop="operatorName" >
        <a-input v-model="form.operatorName" placeholder="请输入操作员姓名" />
      </a-form-model-item>
      <a-form-model-item label="操作员编号" prop="operatorNum" >
        <a-input v-model="form.operatorNum" placeholder="请输入操作员编号" />
      </a-form-model-item>
      <a-form-model-item label="联系电话" prop="phone" >
        <a-input v-model="form.phone" placeholder="请输入联系电话" />
      </a-form-model-item>
      <a-form-model-item label="使用登记证" prop="registration" >
        <file-upload type="image" :count="1" :defaultList="form.registrationArr" @input="getImg($event, 'registration')"></file-upload>
      </a-form-model-item>
      <a-form-model-item label="使用标志" prop="sign" >
        <file-upload type="image" :count="1" :defaultList="form.signArr" @input="getImg($event, 'sign')"></file-upload>
      </a-form-model-item>
      <a-form-model-item label="验收记录" prop="acceptanceRecords" >
        <file-upload type="image" :count="1" :defaultList="form.acceptanceRecordsArr" @input="getImg($event, 'acceptanceRecords')"></file-upload>
      </a-form-model-item>
      <a-form-model-item label="操作手证件" prop="operatorDocument" >
        <file-upload type="image" :count="1" :defaultList="form.operatorDocumentArr" @input="getImg($event, 'operatorDocument')"></file-upload>
      </a-form-model-item>
<!--      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getDevice, addDevice, updateDevice } from '@/api/device/device'
import { getCityList } from '@/api/logistics'
import {guid} from "@/utils/ruoyi";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 省份列表
      provinceList: [],
      // 城市列表
      cityList: [],
      // 区域列表
      areaList: [],
      // 表单参数
      form: {
        id: null,

        provinceName: null,

        provinceCode: null,

        cityName: null,

        cityCode: null,

        areaName: null,

        areaCode: null,

        qrCode: null,

        imgs: null,

        imgsArr: [],

        name: null,

        number: null,

        model: null,

        address: null,

        nextInspectionTime: null,

        operatorName: null,

        operatorNum: null,

        phone: null,

        registration: null,

        registrationArr: [],

        sign: null,

        signArr: [],

        acceptanceRecords: null,

        acceptanceRecordsArr: [],

        operatorDocument: null,

        operatorDocumentArr: [],

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        provinceName: [
          { required: true, message: '省名称不能为空', trigger: 'blur' }
        ],
        provinceCode: [
          { required: true, message: '省编码不能为空', trigger: 'blur' }
        ],
        cityName: [
          { required: true, message: '市名称不能为空', trigger: 'blur' }
        ],
        cityCode: [
          { required: true, message: '市编码不能为空', trigger: 'blur' }
        ],
        areaName: [
          { required: true, message: '区名称不能为空', trigger: 'blur' }
        ],
        areaCode: [
          { required: true, message: '区编码不能为空', trigger: 'blur' }
        ],
        qrCode: [
          { required: true, message: '设备二维码地址不能为空', trigger: 'blur' }
        ],
        imgs: [
          { required: true, message: '设备图片,多个使用逗号隔开不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '设备名称不能为空', trigger: 'blur' }
        ],
        number: [
          { required: true, message: '设备编号不能为空', trigger: 'blur' }
        ],
        model: [
          { required: true, message: '设备型号不能为空', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '使用地点不能为空', trigger: 'blur' }
        ],
        operatorName: [
          { required: true, message: '操作员姓名不能为空', trigger: 'blur' }
        ],
        operatorNum: [
          { required: true, message: '操作员编号不能为空', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '联系电话不能为空', trigger: 'blur' }
        ],
        registration: [
          { required: true, message: '使用登记证不能为空', trigger: 'blur' }
        ],
        sign: [
          { required: true, message: '使用标志不能为空', trigger: 'blur' }
        ],
        acceptanceRecords: [
          { required: true, message: '验收记录不能为空', trigger: 'blur' }
        ],
        operatorDocument: [
          { required: true, message: '操作手证件不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
    this.getProvinceList()
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    // 清除图片列表
    clearImage() {
      this.form.imgsArr = []
    },
    /**上传图片成功回调 */
    getImg(info, fieldName) {
      if (info.length === 0) {
        this.form[fieldName] = ''
        this.form[`${fieldName}Arr`] = []
      } else {
        if (this.form[`${fieldName}Count`] === 1) {
          this.form[fieldName] = info[0].url
          this.form[`${fieldName}Arr`] = info
        } else {
          this.form[fieldName] = info.map(ele => { return ele.url }).join(",")
          this.form[`${fieldName}Arr`] = info
        }
      }
    },
    // 获取省份列表
    getProvinceList() {
      getCityList({level: 1}).then(res => {
        this.provinceList = res.data
      })
    },
    // 选择省份
    selectProvince(item) {
      this.form.provinceCode = item
      this.getCityList(item)
      this.form.cityCode = null
      this.form.areaCode = null
    },
    // 获取城市列表
    getCityList(parentId) {
      getCityList({level: 2, parentId: parentId}).then(res => {
        this.cityList = res.data
      })
    },
    // 选择城市
    selectCity(item) {
      this.form.cityCode = item
      this.getAreaList(item)
      this.form.areaCode = null
    },
    // 获取区域列表
    getAreaList(parentId) {
      getCityList({level: 3, parentId: parentId}).then(res => {
        this.areaList = res.data
      })
    },
    // 选择区域
    selectArea(item) {
      this.form.areaCode = item
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      console.log('=========重置前', this.form)
      this.$nextTick(() => {

        if(this.$refs.form){
          this.$refs.form.resetFields()
          this.form = {
            id: null,
            provinceName: null,
            provinceCode: null,
            cityName: null,
            cityCode: null,
            areaName: null,
            areaCode: null,
            qrCode: null,
            imgs: null,
            imgsArr: [],
            name: null,
            number: null,
            model: null,
            address: null,
            nextInspectionTime: null,
            operatorName: null,
            operatorNum: null,
            phone: null,
            registration: null,
            registrationArr: [],
            sign: null,
            signArr: [],
            acceptanceRecords: null,
            acceptanceRecordsArr: [],
            operatorDocument: null,
            operatorDocumentArr: [],
            createTime: null,
            remark: null,
          }
        }
        console.log('=========重置后', this.form)
        this.form = {...this.form}
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {

      this.clearImage()
      this.formType = 1
      this.open = true
      this.reset()
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getDevice({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
        this.getCityList(this.form.provinceCode)
        this.getAreaList(this.form.cityCode)
        this.form.imgsArr = []
        this.form.registrationArr = []
        this.form.signArr = []
        this.form.acceptanceRecordsArr = []
        this.form.operatorDocumentArr = []
        let that = this
        if (this.form.imgs) {
          this.form.imgs.split(",").map(item => {
            this.form.imgsArr.push({
              uid: guid(8, 10),
              name: item,
              status: 'done',
              halfUrl: item,
              url: item,
              path: item
            })
          })
        }
        if (this.form.registration) {
          this.form.registrationArr.push({
            uid: guid(8, 10),
            name: that.form.registration,
            status: 'done',
            halfUrl: that.form.registration,
            url: that.form.registration,
            path: that.form.registration
          })
        }
        if (this.form.sign) {
          this.form.signArr.push({
            uid: guid(8, 10),
            name: that.form.sign,
            status: 'done',
            halfUrl: that.form.sign,
            url: that.form.sign,
            path: that.form.sign
          })
        }
        if (this.form.acceptanceRecords) {
          this.form.acceptanceRecordsArr.push({
            uid: guid(8, 10),
            name: that.form.acceptanceRecords,
            status: 'done',
            halfUrl: that.form.acceptanceRecords,
            url: that.form.acceptanceRecords,
            path: that.form.acceptanceRecords
          })
        }
        if (this.form.operatorDocument) {
          this.form.operatorDocumentArr.push({
            uid: guid(8, 10),
            name: that.form.operatorDocument,
            status: 'done',
            halfUrl: that.form.operatorDocument,
            url: that.form.operatorDocument,
            path: that.form.operatorDocument
          })
        }
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateDevice(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addDevice(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
